import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-field-inputs"
export default class extends Controller {

  changeInput(event){
    const value = event.target.value
    const options = event.target.closest(".template").querySelector(".checkboxes_values")
    const booleans = event.target.closest(".template").querySelector(".boolean_values")
    if(value == "checkboxes" || value == "select"){
      options.hidden = false
      booleans.hidden = true
    }else if(value == "boolean"){
      options.hidden = true
      booleans.hidden = false
    }else{
      booleans.hidden = true
      options.hidden = true
    }
  }

}
