import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="form-actions"
export default class extends Controller {

  static targets = ["disableableItem", "hiddenItem", "filename"]

  connect() {
    console.log("Form Actions Controller")
  }

  change_file(event){
    if(this.filenameTarget){
      if(event.target.value){
        this.filenameTarget.innerHTML = event.target.value.replace(/.*[\/\\]/, '')
      }else{
        this.filenameTarget.innerHTML = this.filenameTarget.dataset.defaultValue
      }
    }
  }

  toggleDisableableItems(){
    this.disableableItemTargets.forEach(ele => {
      ele.toggleAttribute("disabled")
    });
  }

  submitForm(event){
    const spinner = event.target.form.querySelector(".loading-spinner")
    console.log(spinner)
    console.log(event.target.form)
    if(spinner){
      event.target.form.addEventListener("turbo:submit-start", async () => {
        spinner.hidden = false
      })
      event.target.form.addEventListener("turbo:submit-end", async () => {
        spinner.hidden = true
      })
    }
    event.target.form.requestSubmit()
  }

  search(event){
    const spinner = event.target.form.querySelector(".loading-spinner")
    let delay = 500
    if(event.params.wait){
      delay = event.params.wait
    }
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      if(spinner){
        event.target.form.addEventListener("turbo:submit-start", async () => {
          spinner.hidden = false
        })
        event.target.form.addEventListener("turbo:submit-end", async () => {
          spinner.hidden = true
        })
      }
      event.target.form.requestSubmit()
    }, delay)
  }

  toggleHiddenDeductionItems(event){
    const hiddenTarget = document.querySelectorAll(`.${event.target.dataset.hiddenTarget}`)
    if(hiddenTarget){
      if(event.target.value == "Modify"){
        hiddenTarget.forEach(element => {
          element.hidden = !element.hidden
        });
      }else{
        hiddenTarget.forEach(element => {
          element.hidden = (element.dataset.default == "hidden")
        });
      }
    }
  }

  toggleHiddenItems(event){
    let hiddenElements = this.hiddenItemTargets
    if(event.target.dataset.hiddenTag){
      hiddenElements = this.element.querySelectorAll(event.target.dataset.hiddenTag)
    }
    hiddenElements.forEach(ele => {
      console.log(ele)
      ele.toggleAttribute("hidden")
    });
  }

}
