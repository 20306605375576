import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "body"];

  remove(event) {
    event.preventDefault();
    event.target.closest('.template').querySelector('.destroy_field').value = true
    event.target.closest('.template').style.display = "none";
  }

  add(event) {
    event.preventDefault();
    this.bodyTarget.insertAdjacentHTML(
      "beforeEnd",
      this.templateTarget.innerHTML.replace(
        /__CHILD_INDEX__/g,
        new Date().getTime().toString()
      )
    );
  }

}
