import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="deductions"
export default class extends Controller {

  co_nums = new TomSelect("#finance_deduction_item_co_num",{
    valueField: 'id',
    labelField: 'co_num',
    searchField: ['id', 'co_num', 'invoice', 'cust_po'],
    // fetch remote data
    load: function(query, callback) {
      let url = document.querySelector("#finance_deduction_item_co_num").dataset.dataUrl
      if(query){
        url = url + '/search/' + encodeURIComponent(query);
      }
      fetch(url)
      .then(response => response.json())
      .then(json => {
        console.log(json)
        callback(json);
      }).catch(()=>{
        callback();
      });
    },



    render: {
      option: function(data, escape) {
        return `<div><h6>${data.cust_po} - [${data.invoice}]</h6>
                    <div class="flex gap-2">
                        <h6 class="text-gray-400">${data.co_num}</h6>
                        <h6 class="text-gray-400">${data.date}</h6>
                    </div></div>`;
      },
      item: function(data, escape) {
        return `<div><h6>${data.cust_po} - [${data.invoice}]</h6>
                    <div class="flex gap-2">
                        <h6 class="text-gray-400">${data.co_num}</h6>
                        <h6 class="text-gray-400">${data.date}</h6>
                    </div></div>`;
      }
    }
  });

  connect() {
    const input = document.querySelector("#finance_deduction_item_co_num")
    console.log(input.dataset, input.dataset.load)
    if(input.dataset.load == 'true'){
      this.co_nums.load();
    }
  }
}

