import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select"
import {post, get} from "@rails/request.js"

// Connects to data-controller="materials"
export default class extends Controller {
  static targets = ["materials"]

  connect() {
    console.log("Materials Controller Connected")
  }

  async createNewMaterial(body){
    const node = document.createElement("div");
    node.innerHTML = `${body.newMaterialLink}`
    document.querySelector("#aside-modal-content").appendChild(node)
    const element = document.querySelector(body.linkQuery)
    console.log(element)
    setTimeout(() => {
      console.log(element)
      if(element){
        element.click()
        element.remove()
      }
    }, 50);
  }

  materialsTargetConnected(element){
    console.log("Material Connected")
    const $this = this
    const newGo = element.dataset.newGo
    const testLab = element.dataset.testLab
    const materials = new TomSelect(element,{
      valueField: 'id',
      labelField: 'name',
      searchField: ["name"],
      optgroupField: 'label',
      preload: true,
      optgroups: [
        {value: 'completed', label: 'Completed'},
        {value: 'pending', label: 'Pending'},
      ],
      render: {
        optgroup_header: function(data, escape) {
          return '<h4 class="optgroup-header">' + escape(data.label) +'</h4>';
        }
      },
      // fetch remote data
      load: async function(query, callback) {
        let response = await get("/materials/search", {query: {
            q: encodeURIComponent(query),
            new_go: newGo,
            test_lab: testLab
          },
          responseKind: 'json',
          contentType: 'application/json'
        })
        if(response.ok){
          const json = await response.json
          callback(json)
        }else{
          callback()
        }
      },
      create: async function(input, callback) {
        const response = await post(`/materials/`, {
          body: {
            material: {
              name: input
            }
          },
          contentType: "application/json",
          headers: {},
          query: {},
          responseKind: "json"
        })
        if (response.ok){
          const body = await response.json
          callback(body);
          await $this.createNewMaterial(body)
        }else{
          const body = await response.json
          document.querySelector('#material_errors').innerHTML += JSON.parse(body.errors)
        }
      }
    });
  }
}
