import { Controller } from "@hotwired/stimulus"
import Toastify from "toastify-js";
import { showBackdrop, hideBackdrop } from "../components/backdrop"
import { animateCSS, openCollapse, closeCollapse } from "../components/script"
import tippy from "tippy.js";
import Litepicker from "litepicker";

export default class extends Controller {
  static targets = ["collapse", "toast", "closeModal", "dropdown", "refreshPage", "tooltip", "removeMe", "tippyDropdown"]
  asideModal = document.querySelector("#aside-modal");
  modal = document.querySelector("#top-modal");

  tab(event) {
    let toggling = false
    const target = event.target.dataset.target
    const tabs = event.target.closest(".tabs");
    let activeTabTrigger = tabs.querySelector(".tab-nav .active")
    if (activeTabTrigger){
    }else{
      activeTabTrigger = tabs.querySelector(".tab-nav .nav-link");
      activeTabTrigger.classList.add('active')
    }
    const activeTab = tabs.querySelector(".collapse.open");
    const targetedTab = tabs.querySelector(target);
    const trigger = tabs.querySelector(`[data-target="${target}"].nav-link`)
    if (toggling) return;
    if (activeTabTrigger === trigger) return;

    // Trigger
    activeTabTrigger.classList.remove("active");
    trigger.classList.add("active");

    // Tab
    // Close
    toggling = true;

    closeCollapse(activeTab, () => {
      openCollapse(targetedTab, () => {
        toggling = false;
      });
    });
  };

  refreshPageTargetConnected(element) {
    element.remove()
    location.reload()
  }

  removeMeTargetConnected(element) {
    setTimeout(() => {
      element.remove()
    }, 3000);
  }

  toggleAccordionCollapse(event){

    const collapseTrigger = event.target
    const selector = '[data-toggle="collapse"]'
    collapseTrigger.classList.toggle("active");

    // Collapse
    const collapses = document.querySelectorAll(`${collapseTrigger.dataset.target}`);
    collapses.forEach((collapse) => {
      if (collapse.classList.contains("open")) {
        closeCollapse(collapse);
      } else {
        openCollapse(collapse);
      }
    });

    // Accordion
    const accordion = collapseTrigger.closest(".accordion");
    if (accordion) {
      const accordionTriggers = accordion.querySelectorAll(selector);
      accordionTriggers.forEach((accordionTrigger) => {
        if (accordionTrigger !== collapseTrigger) {
          accordionTrigger.classList.remove("active");
        }
      });

      const accordions = accordion.querySelectorAll(".collapse");
      accordions.forEach((accordion) => {
        if (accordion.classList.contains("open")) {
          closeCollapse(accordion);
        }
      });
    }
  };

  litepickerTargetConnected(element){
    const today = new Date();
    const priorDate = new Date(new Date().setDate(today.getDate() - 30));
    const picker = new Litepicker({
      element: element,
      numberOfColumns: 2,
      numberOfMonths: 2,
      startDate: priorDate,
      endDate: today,
      singleMode: false
    })
    picker.updateInput = function() {
      Litepicker.prototype.updateInput.call(this);
      // element.options.element.trigger('change');
      // element.options.elementEnd.trigger('change');

      if (element.dataset.target) {
        document.querySelector(element.dataset.target).click()
      }
    };
  }


  dropdownTargetConnected(element){
    console.log(element.dataset)
    let tippyPlacement = ""
    if(element.dataset.tippyPlacement){
      tippyPlacement = element.dataset.tippyPlacement
    }else{
      tippyPlacement = "bottom-start"
    }

    let tippyArrow = false
    if(element.dataset.tippyArrow){
      tippyArrow = true
    }else{
      tippyArrow = false
    }

    console.log("DJDKJDKJ")
    console.log(tippyPlacement)

    tippy(element, {
      theme: "light-border",
      offset: [0, 8],
      arrow: tippyArrow,
      placement: tippyPlacement,
      interactive: true,
      allowHTML: true,
      animation: "shift-toward-extreme",
      content: (reference) => {
        let dropdownMenu
        if(reference.closest(".dropdown").querySelector(".dropdown-menu")){
          dropdownMenu = reference.closest(".dropdown").querySelector(".dropdown-menu")
        }else{
          dropdownMenu = reference.closest(".dropdown").querySelector(".custom-dropdown-menu")
        }
        dropdownMenu = dropdownMenu.outerHTML;
        return dropdownMenu;
      },
      appendTo: () => document.body,
    });
  }

  connect() {

  }

  tooltipTargetConnected(element){
    tippy(element, {
      content: element.dataset.tippyContent
    })
  }

  closeAsideModalTargetConnected(element){
    element.remove();
    this.closeAsideModal()
  }

  closeTopModalTargetConnected(element){
    element.remove();
    this.closeTopModal()
  }

  showAsideModal() {
    showBackdrop();
    this.asideModal.classList.add("active");
    const animation = this.asideModal.dataset.animations.split(", ")[0];
    const modalContent = this.asideModal.querySelector(".modal-content");
    animateCSS(modalContent, animation);
    // modalContent.innerHTML = `<div class="h-84"></div>`
  };

  // Close
  closeAsideModal() {
    hideBackdrop();
    const animation = this.asideModal.dataset.animations.split(", ")[1];
    const modalContent = this.asideModal.querySelector(".modal-content");
    animateCSS(modalContent, animation).then(() => {
      this.asideModal.classList.remove("active");
    });
  };

  showTopModal() {
    showBackdrop();
    this.modal.classList.add("active");
    const animation = this.modal.dataset.animations.split(", ")[0];
    const modalContent = this.modal.querySelector(".modal-content");
    animateCSS(modalContent, animation);
  };

  closeTopModal() {
    hideBackdrop();
    const animation = this.modal.dataset.animations.split(", ")[1];
    const modalContent = this.modal.querySelector(".modal-content");
    animateCSS(modalContent, animation).then(() => {
      this.modal.classList.remove("active");
    });
  };


  tippyDropdownTargetConnected(element){
    console.log("TIPPY DROPDOWN")
    const dropdown = element.closest(".dropdown")
    let dropdownMenu
    if(dropdown.querySelector(".dropdown-menu")){
      dropdownMenu = dropdown.querySelector(".dropdown-menu")
    }else{
      dropdownMenu = dropdown.querySelector(".custom-dropdown-menu")
    }
    tippy(element, {
      interactive: true,
      trigger: 'click',
      theme: "light-border",
      maxWidth: 'none',
      offset: [0, 8],
      arrow: false,
      placement: "bottom-start",
      allowHTML: true,
      animation: "shift-toward-extreme",
      content: dropdownMenu,
      onHidden(){
        dropdown.append(dropdownMenu);
      },
      onShow({popper}){
        popper.querySelector(".tippy-content").append(dropdownMenu);
      },
    });
  }

  toastTargetConnected(element){
    const elem = element.cloneNode(true)
    element.remove()
    delete elem.dataset.controller
    elem.classList.remove("hidden")
    let toast = Toastify({
      node: elem,
      duration: 3000,
      newWindow: true,
      close: false,
      gravity: "bottom",
      position: "right",
      stopOnFocus: true,
      onClick: function () {
        toast.hideToast();
      },
    }).showToast();
  }

  setClass(event){
    const element = document.querySelector(`#${event.target.dataset.target}`)
    element.classList.toggle("mystyle");
    if(element){
      if(event.target.value == "Approve"){
        element.className = 'bg-[#f0fdf4]'
      }else if(event.target.value == "Reject"){
        element.className = 'bg-[#fef2f2]'
      }else if(event.target.value == "Modify"){
        element.className = 'bg-[#fefce8]'
      }else if(event.target.value == "Waive"){
        element.className = 'bg-[#f5f5f4]'
      }else{
        element.className = ''
      }
    }
  }

}


