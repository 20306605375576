import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

// document.addEventListener("turbo:frame-missing", async (event) => {
//     event.preventDefault()
//     event.detail.visit("/")
//     // event.detail.resume()
// })

export { application }
