import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select";

// Connects to data-controller="tom-select"
export default class extends Controller {
  static targets = ["select", "emails"]

  selectTargetConnected(element) {
    new TomSelect(element,{
      plugins: {
        remove_button:{
          title:'Remove this item',
        }
      },
      sortField: {
        field: "text",
        direction: "asc"
      },
      render: {
        optgroup_header: function(data, escape) {
          return '<h3 class="optgroup-header sticky top-0">' + escape(data.label) +'</h3>';
        }
      }
    });
  }

  emailsTargetConnected(element){
    const emails = new TomSelect(element,{
      create: true,
      createFilter: element.dataset.regex,
    });
  }

}

