import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="filter"
export default class extends Controller {
  static targets = [ "searchField", "item" ]

  connect() {
  }

  search(event){
    this.itemTargets.forEach(element => {
      this.searchWithinText(element, event.target.value)
    });
  }

  searchWithinText(element, searchValue) {
    if (element.textContent.toLowerCase().includes(searchValue)) {
      element.hidden = false
    }else{
      element.hidden = true
    }
  }


}
