import { Controller } from "@hotwired/stimulus"
import { showBackdrop, hideBackdrop } from "../components/backdrop"

// Connects to data-controller="menu"
export default class extends Controller {
  root = document.documentElement;

  menuBar = document.querySelector(".menu-bar");
  menuItems = document.querySelector(".menu-items");
  menuType = localStorage.getItem("menuType");

  connect() {
    console.log("connected to menu")
    if (this.menuType) {
      this.root.classList.add(this.menuType);
      this.menuBar.classList.add(this.menuType);
    }
  }

// Open Menu Detail
openMenuDetail(event) {
  const menuLink = event.target.closest(".link");
  const menu = menuLink.dataset.target;
  const selectedMenu = this.menuBar.querySelector(menu);

  if (selectedMenu && !this.menuBar.classList.contains("menu-wide")) {
    const isSelectedMenuOpen = selectedMenu.classList.contains("open");

    this.hideMenuDetail();

    if (selectedMenu && !isSelectedMenuOpen) {
      showBackdrop(true);
      selectedMenu.classList.add("open");
    } else {
      hideBackdrop();
    }
  }
};

// Hide Menu Detail
hideMenuDetail() {
  this.menuBar.querySelectorAll(".menu-detail.open").forEach((menuDetail) => {
    hideBackdrop();

    if (!this.menuBar.classList.contains("menu-wide")) {
      menuDetail.classList.remove("open");
    }
  });
};

// Open Menu
openMenu() {
  this.root.classList.remove("menu-hidden");
  this.menuBar.classList.remove("menu-hidden");
};

// Close Menu
closeMenu() {
  this.root.classList.add("menu-hidden");
  this.menuBar.classList.add("menu-hidden");
};

// Toggle Menu
toggleMenu() {
  if (this.menuBar.classList.contains("menu-hidden")) {
    this.openMenu();
  } else {
    this.closeMenu();
  }
};

// Switch Menu Type
switchMenuType(id) {
  const openMenu = this.menuBar.querySelector(".menu-detail.open");

  this.root.classList.remove("menu-icon-only");
  this.menuBar.classList.remove("menu-icon-only");

  this.root.classList.remove("menu-wide");
  this.menuBar.classList.remove("menu-wide");
  this.deactivateWide();

  this.root.classList.remove("menu-hidden");
  this.menuBar.classList.remove("menu-hidden");

  switch (id) {
    case "icon-only":
      this.root.classList.add("menu-icon-only");
      this.menuBar.classList.add("menu-icon-only");
      localStorage.setItem("menuType", "menu-icon-only");

      if (openMenu) {
        showBackdrop(true);
      }

      break;
    case "wide":
      this.root.classList.add("menu-wide");
      this.menuBar.classList.add("menu-wide");
      localStorage.setItem("menuType", "menu-wide");

      this.activateWide();

      if (openMenu) {
        hideBackdrop();
      }

      break;
    case "hidden":
      this.root.classList.add("menu-hidden");
      this.menuBar.classList.add("menu-hidden");
      localStorage.setItem("menuType", "menu-hidden");

      this.hideMenuDetail();

      break;
    default:
      localStorage.removeItem("menuType");

      if (openMenu) {
        showBackdrop(true);
      }
  }
};

// Activate Wide
activateWide() {
  this.menuBar.querySelector(".menu-header").classList.remove("hidden");

  this.menuBar.querySelectorAll(".menu-items .link").forEach((menuLink) => {
    const target = menuLink.dataset.target;

    const selectedMenu = this.menuBar.querySelector(".menu-detail" + target);
    if (selectedMenu) {
      selectedMenu.classList.add("collapse");
      menuLink.setAttribute("data-toggle", "collapse");
      menuLink.after(selectedMenu);
    }
  });
};

// Deactivate Wide
deactivateWide() {
  this.root.classList.remove("menu-wide");
  this.menuBar.classList.remove("menu-wide");

  this.menuBar.querySelector(".menu-header").classList.add("hidden");

  this.menuBar.querySelectorAll(".menu-items .link").forEach((menuLink) => {
    const target = menuLink.dataset.target;

    const selectedMenu = this.menuBar.querySelector(".menu-detail" + target);
    if (selectedMenu) {
      selectedMenu.classList.remove("collapse");
      menuLink.removeAttribute("data-toggle", "collapse");
      this.menuItems.after(selectedMenu);
    }
  });
};

// Watch the viewport width
watchWidth() {
  const sm = 640;
  const md = 768;
  const lg = 1024;
  const xl = 1280;

  const viewportWidth =
    window.innerWidth || document.documentElement.clientWidth;

  if (viewportWidth < sm) {
    const openMenu = this.menuBar.querySelector(".menu-detail.open");

    if (!openMenu) {
      this.closeMenu();
    }
  }

  if (viewportWidth > sm) {
    this.openMenu();
  }
};

 removeThese(){
   // On resize events, recalculate
   window.addEventListener(
     "resize",
     () => {
       this.watchWidth();
     },
     false
   );

   // Auto-activate Wide
   if (this.menuBar.classList.contains("menu-wide")) {
     this.activateWide();
   }

   // Hide Menu - When Clicked Elsewhere
   document.addEventListener("click", (event) => {
     if (
       !event.target.closest(".menu-bar") &&
       !this.menuBar.classList.contains("menu-wide")
     ) {
       hideMenuDetail();
     }
   });

  //  on(".top-bar", "click", "[data-toggle='menu']", (event) => {
  //    toggleMenu(event);
  //  });

   on(".menu-items", "click", ".link", (event) => {
     openMenuDetail(event);
   });

   on(".menu-bar", "click", "[data-toggle='menu-type']", (event) => {
     const id = event.target.closest("[data-toggle='menu-type']").dataset.value;
     switchMenuType(id);
   });
   };

   // Show Active Page
   showActivePage() {
   const pageUrl = window.location.href.split(/[?#]/)[0];

   const pageLinkSelector = ".menu-bar a";

   const pageLinks = document.querySelectorAll(pageLinkSelector);

   if (!pageLinks) return;

   pageLinks.forEach((pageLink) => {
     if (pageLink.href === pageUrl) {
       pageLink.classList.add("active");

       const mainMenuTrigger = pageLink.closest(".menu-detail");

       if (!mainMenuTrigger) return;

       const mainMenu = document.querySelector(
         '.menu-items .link[data-target="[data-menu=' +
           mainMenuTrigger.dataset.menu +
           ']"]'
       );

       mainMenu.classList.add("active");
     }
   });
 }




}
