import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="new-deduction"
export default class extends Controller {

  changeDeductionCode(event){
    const text = event.target.options[event.target.selectedIndex].text
    const codeType = document.querySelector("#deduction_code_type")
    if(codeType){
      if(text.includes("CMQ")){
        codeType.hidden = false
      }else{
        codeType.hidden = true
      }
    }
  }

  connect() {
  }
}
